.overlay-loding {
  overflow: hidden;
}

.card {

  &-register {
    border: 1px solid #f6f5f5;
    box-sizing: border-box;
    box-shadow: 0px 10px 26px rgba(176,176,176,0.25);
    border-radius: 10px;
  }
  &-company {
    border: 1px solid #f6f5f5;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(176,176,176,0.25);
    border-radius: 10px;
  }
}

.bg {
  &-purple-dark {
    background-color: #463351 !important;
  }
}

.img-company-profile {
  
  width: 96px;
  height: 96px;
  background-color: #f6f5f5;
  &:before{
    background-color: #000;
  }
    
}

.img-company-page {
  width: 100%;
  height: 100%;
  background-color: #f6f5f5;
  display: inline-block;
  border: 1px solid #f6f5f5;
  box-sizing: border-box;
    
}
.static-content__title {
  font-size: 1.625rem;
  color: #ef4a37;
  margin: 20px 0;
  text-align: center;
  font-weight: 700;
}

.static-content__subtitle {
  font-size: 1.125rem;
  text-align: center;
}

.static-content__section-title {
  font-size: 1.125rem;
  color: #463351;
  font-weight: 700;
  line-height: 27px;
  margin: 25px 0 10px 0;
}
.static-content__subsection-number {
  margin-right: 10px;
  font-weight: 700;
}
.static-content__text {
  font-weight: 300;
  margin-bottom: 15px;
  line-height: 29px;
  display: block;
  text-align: justify;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}