.img {



  &-company {


    &-details {
      display: block;
      width: 10vw;
      height: 10vw;
      max-width: 100%;
      min-width: 80px;
      min-height: 80px;
      object-fit: cover;
      // display: inline-flex;
      // width: 100%;
      // max-width: 100px;
      // min-width: 80px;
      // height: auto;
      // max-height: 100px;
      // min-height: 80px;
      
      // @media only screen and (min-width: 992px) {
      //   width: 100%;
      //   max-width: 160px;
      //   max-height: 160px;
      // }

      // @media only screen and (min-width: 992px) {
      //   width: 100%;
      //   max-width: 160px;
      //   max-height: 160px;
      // }
    }

  }
}